import EdnaWithTextLogo from "./EdnaWithTextLogo";
export const Legal = () => {
  return (
    <div className="container">
      <div className="header">
        <div className="logo-and-links">
          <EdnaWithTextLogo color="#ffffff" />
        </div>
      </div>

      <div className="legal">
        <main>
          <h1>Edna Privacy Policy</h1>
          <h2>Introduction</h2>
          <p>
            Edna Technologies, LLC ("Edna" or "We") respect your privacy and are
            committed to protecting it through our compliance with this policy.
            This policy describes the types of information we may collect from
            you or that you may provide when you visit Edna’s websites,
            including withedna.com & ednatech.io (the “Website”), access our
            Edna Invest platform through the Website or use our mobile
            application (“Edna Invest”) and our practices for collecting, using,
            maintaining, protecting, and disclosing that information.
          </p>
          <p>This policy applies to information we collect:</p>
          <ul>
            <li>On the Website.</li>
            <li>
              In email, text, and other electronic messages between you and
              Edna.
            </li>
            <li>
              {" "}
              Through mobile and/or desktop applications you download, which
              provide dedicated non-browser-based interaction between you and
              Edna.
            </li>{" "}
            <li>
              When you interact with our advertising and applications on
              third-party websites and services, if those applications or
              advertising include links to this policy.
            </li>
          </ul>
          <p>It does not apply to information collected by:</p>
          <ul>
            <li>
              Us offline or through any other means, including on any other
              website operated by Edna or any third party (including our
              affiliates and subsidiaries); or
            </li>
            <li>
              {" "}
              Any third party (including our affiliates and subsidiaries),
              including through any application or content (including
              advertising) that may link to or be accessible from or through the
              Website.
            </li>
          </ul>
          <p>
            Please read this policy carefully to understand our policies and
            practices regarding your information and how we will treat it. If
            you do not agree with our policies and practices, your choice is not
            to use the Website or Edna Invest. By accessing or using the Website
            or Edna Invest, you agree to this privacy policy. This policy may
            change from time to time (see Changes to Our Privacy Policy). Your
            continued use of the Website or Edna Invest after we make changes is
            deemed to be acceptance of those changes, so please check the policy
            periodically for updates.
          </p>
          <h2>Children Under the Age of 18</h2>
          <p>
            Our Website is not intended for children under 18 years of age. No
            one under age 16 may provide any personal information to or on the
            Website. We do not knowingly collect personal information from
            children under 18. If you are under 18, do not use or provide any
            information on Edna Invest or through any of its features, register
            on the Website, or provide any information about yourself to us,
            including your name, address, telephone number, email address, or
            any user name you may use. If we learn we have collected or received
            personal information from a child under 18 without verification of
            parental consent, we will delete that information. If you believe we
            might have any information from or about a child under 18, please
            contact us at{" "}
            <a href="mailto:legal@withedna.com">legal@withedna.com</a>.
          </p>
          <h2>Information We Collect About You and How We Collect It</h2>
          <p>
            We may collect several types of information from and about users of
            the Website or Edna Invest, including information:
          </p>
          <ul>
            <li>
              By which you may be personally identified, such as name, postal
              address, email address, telephone number, social security number
              or any other identifier by which you may be contacted online or
              offline] ("<strong>personal information</strong>");
            </li>
            <li>
              {" "}
              About your internet connection, the equipment you use to access to
              the Website or Edna Invest, and usage details.
            </li>
          </ul>
          <p>We collect this information:</p>
          <ul>
            <li>Directly from you when you provide it to us.</li>
            <li>
              {" "}
              Automatically as you navigate through the Website or Edna Invest.
              Information collected automatically may include usage details, IP
              addresses, and information collected through cookies, web beacons,
              and other tracking technologies.
            </li>
            <li>
              From third party partners, generally, and as it relates to Edna
              Invest, from your employer.
            </li>
          </ul>
          <p>
            <em>Information You Provide to Us </em>
          </p>
          <p>
            The information we collect on the Website or through Edna Invest may
            include:
          </p>
          <ul>
            <li>
              Information that you provide by filling in forms on the Website or
              through Edna Invest. This includes information provided at the
              time of registering to use Edna Invest. We may also ask you for
              information when you report a problem with Edna Invest.
            </li>
            <li>
              {" "}
              Records and copies of your correspondence (including email
              addresses), if you contact us.
            </li>
            <li>
              {" "}
              Your responses to surveys that we might ask you to complete for
              research purposes.
            </li>
          </ul>
          <p>
            <em>
              Information We Collect Through Automatic Data Collection
              Technologies
            </em>
          </p>
          <p>
            As you navigate through and interact with the Website and Edna
            Invest, we may use automatic data collection technologies to collect
            certain information about your equipment, browsing actions, and
            patterns, including:
          </p>
          <ul>
            <li>
              Details of your visits to Edna Invest and other communication data
              and the resources that you access and use on the Website.
            </li>
            <li>
              {" "}
              Information about your devices (including, but not limited to,
              mobile devices and computers) owned or otherwise controlled by you
              ("<strong>Device</strong>")
            </li>
          </ul>
          <p>
            The information we collect automatically may include personal
            information, or we may maintain it or associate it with personal
            information we collect in other ways or receive from third parties.
            It helps us to improve Edna Invest and to deliver a better and more
            personalized service, including by enabling us to:
          </p>
          <ul>
            <li>Estimate our audience size and usage patterns.</li>
            <li>
              {" "}
              Store information about your preferences, allowing us to customize
              Edna Invest according to your individual interests.
            </li>
            <li> Speed up your searches.</li>
            <li> Recognize you when you return to Edna Invest.</li>
          </ul>
          <p>
            The technologies we use for this automatic data collection may
            include:
          </p>
          <ul>
            <li>
              <strong>Cookies (or browser cookies)</strong>. A cookie is a small
              file placed on the hard drive of your computer. You may refuse to
              accept browser cookies by activating the appropriate setting on
              your browser. However, if you select this setting you may be
              unable to access certain parts of Edna Invest. Unless you have
              adjusted your browser setting so that it will refuse cookies, our
              system will issue cookies when you direct your browser to Edna
              Invest.
            </li>
            <li>
              {" "}
              <strong>Web Beacons.</strong> Pages of our Website and Edna Invest
              and our emails may contain small electronic files known as web
              beacons (also referred to as clear gifs, pixel tags, and
              single-pixel gifs) that permit the Edna, for example, to count
              users who have visited those pages or opened an email and for
              other related website statistics (for example, recording the
              popularity of certain website content and verifying system and
              server integrity).
            </li>
          </ul>
          <h2>How We Use Your Information</h2>
          <p>
            We use information that we collect about you or that you provide to
            us, including any personal information:
          </p>
          <ul>
            <li>To present the Website and Edna Invest to you. </li>
            <li>
              To provide you with information, products, or services that you
              request from us.{" "}
            </li>
            <li>To fulfill any other purpose for which you provide it.</li>
            <li>
              {" "}
              To provide you with notices about your account, including
              expiration and renewal notices.
            </li>
            <li>
              {" "}
              To carry out our obligations and enforce our rights arising from
              any contracts entered into between you and us, including for
              billing and collection.
            </li>
            <li>
              To notify you about changes to the Website or Edna Invest or any
              products or services we offer or provide though it.
            </li>
            <li>
              {" "}
              In any other way we may describe when you provide the information.
              For any other purpose with your consent.
            </li>
          </ul>
          <h2>Disclosure of Your Information</h2>
          <p>
            We may disclose aggregated information about our users, and
            information that does not identify any individual, without
            restriction.
          </p>
          <p>
            We may disclose personal information that we collect or you provide
            as described in this privacy policy:
          </p>
          <ul>
            <li>To our subsidiaries and affiliates.</li>
            <li>
              {" "}
              To contractors, service providers, and other third parties we use
              to support our business.
            </li>
            <li>
              {" "}
              To a buyer or other successor in the event of a merger,
              divestiture, restructuring, reorganization, dissolution, or other
              sale or transfer of some or all of Edna's assets, whether as a
              going concern or as part of bankruptcy, liquidation, or similar
              proceeding, in which personal information held by Edna about Edna
              Invest users is among the assets transferred.
            </li>
            <li>
              {" "}
              To fulfill the purpose for which you provide it. For example, if
              you give us an email address to use the "email a friend" feature
              of Edna Invest, we will transmit the contents of that email and
              your email address to the recipients.
            </li>
            <li>
              For any other purpose disclosed by us when you provide the
              information.
            </li>{" "}
            <li>With your consent.</li>
          </ul>
          <p>We may also disclose your personal information:</p>
          <ul>
            <li>
              To comply with any court order, law, or legal process, including
              to respond to any government or regulatory request.
            </li>
            <li>
              {" "}
              If we believe disclosure is necessary or appropriate to protect
              the rights, property, or safety of Edna, our customers, or others.
              This includes exchanging information with other companies and
              organizations for the purposes of fraud protection and credit risk
              reduction.
            </li>
          </ul>
          <h2>Choices About How We Use and Disclose Your Information</h2>
          <p>
            We strive to provide you with choices regarding the personal
            information you provide to us. We have created mechanisms to provide
            you with the following control over your information:
          </p>
          <ul>
            <li>
              {" "}
              <strong>Tracking Technologies and Advertising.</strong> You can
              set your browser to refuse all or some browser cookies, or to
              alert you when cookies are being sent. If you disable or refuse
              cookies, please note that some parts of this site may then be
              inaccessible or not function properly.
            </li>
          </ul>{" "}
          We do not control third parties' collection or use of your information
          to serve interest-based advertising. However these third parties may
          provide you with ways to choose not to have your information collected
          or used in this way. You can opt out of receiving targeted ads from
          members of the Network Advertising Initiative ("<strong>NAI</strong>")
          on the NAI's website.
          <h2>Changes to Our Privacy Policy</h2>It is our policy to post any
          changes we make to our privacy policy on this page. If we make
          material changes to how we treat our users' personal information, we
          will notify you by email to the primary email address specified in
          your account and/or through a notice on the Website home page. The
          date the privacy policy was last revised is identified at the top of
          the page. You are responsible for ensuring we have an up-to-date
          active and deliverable email address for you, and for periodically
          visiting Edna Invest and this privacy policy to check for any changes.
          <h2>Contact Information</h2>
          <p>
            To ask questions or comment about this privacy policy and our
            privacy practices, contact us at:{" "}
            <a href="mailto:legal@withedna.com">legal@withedna.com</a>
          </p>
          <em>Last modified: March 29, 2024</em>
        </main>
      </div>
    </div>
  );
};
