import React, {useState} from "react";
import "./EdnaLogo.css";

export default() => {
    const [showLogo, setShowLogo] = useState(true);

    const resetAnimation = () => {
        setShowLogo(false);

        setTimeout(() => {
            setShowLogo(true);
        }, 100);
    };

    const renderContent = () => {
        if (!showLogo) {
            return null;
        }

        return (
            <div className="logo-container">
                <div className="logo-cutout-left"></div>
                <div className="logo-cutout-right"></div>
            </div>
        );
    };

    return (
        <div onClick={() => resetAnimation()} className="logo-outer-container" style={{width: "200px", height: "200px"}}>
            {renderContent()}
        </div>
    );
};