import React from "react";

export default({color}) => {
    return (
        <svg width="100px" height="26px" viewBox="0 0 100 26" version="1.1">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="enda-logo-black" fill={color} fillRule="nonzero">
                    <path
                        d="M41.5924922,21.272816 L41.4004351,21.0840266 L41.4004351,20.1872155 L41.5924922,19.9984015 L42.3602221,19.9984015 C42.8402401,19.9984015 43.3202582,19.5264034 43.3202582,19.0543808 L43.3202582,6.9709495 C43.3202582,6.49892688 42.8402401,6.02692876 42.3602221,6.02692876 L41.5924922,6.02692876 L41.4004351,5.83811481 L41.4004351,4.94130367 L41.5924922,4.75248972 L53.2307498,4.75248972 L53.4467205,4.96489623 L53.4467205,5.69651047 L52.8468848,8.2689566 L52.6548278,8.45777054 L51.8868487,8.45777054 L51.6950408,8.2689566 C51.5029838,6.78213556 50.806995,6.02692876 49.5111705,6.02692876 L47.0395883,6.02692876 C46.5598194,6.02692876 46.0798014,6.49892688 46.0798014,6.9709495 L46.0798014,12.1394343 L49.2951998,12.1394343 C49.7752178,12.1394343 50.2552358,11.6438436 50.2552358,11.1954381 L50.2552358,10.9122294 L50.4470438,10.7234154 L51.3590033,10.7234154 L51.5510603,10.9122294 L51.5510603,14.6411028 L51.3590033,14.8298922 L50.4470438,14.8298922 L50.2552358,14.6411028 L50.2552358,14.3578941 C50.2552358,13.8858715 49.7752178,13.4138733 49.2951998,13.4138733 L46.0798014,13.4138733 L46.0798014,19.0543808 C46.0798014,19.5264034 46.5598194,19.9984015 47.0395883,19.9984015 L49.655151,19.9984015 C52.2709628,19.9984015 52.8947122,18.2047547 53.0628556,16.9067477 L53.2546635,16.7179337 L54.1905367,16.7179337 L54.3345172,16.9067477 L53.974566,21.0840266 L53.782758,21.272816 L41.5924922,21.272816 Z"
                        id="Path"></path>
                    <path
                        d="M61.7071657,21.6032343 C58.99545,21.6032343 56.1400028,19.7624025 56.1400028,15.4435192 C56.1400028,11.5730415 58.99545,9.28377951 61.9951267,9.28377951 C63.8427104,9.28377951 65.0187172,10.0626034 65.7386197,10.8414272 L65.7386197,7.25413368 C65.7386197,6.73492594 65.3305919,6.40453216 64.8505739,6.40453216 L64.5386992,6.40453216 L64.2988147,6.19212565 L64.2988147,5.64932536 L64.4906226,5.43691885 L67.6342799,4.2804916 L68.2821921,4.2804916 L68.4500863,4.44568849 L68.2821921,6.23933526 L68.2821921,19.1251829 C68.2821921,19.5972056 68.6660571,19.9984015 69.1460751,19.9984015 L69.5299401,19.9984015 L69.7219971,20.1872155 L69.7219971,21.0840266 L69.5299401,21.272816 L65.8584374,21.272816 L65.6666294,21.0840266 L65.6666294,19.785995 C64.6826797,21.036817 63.3148649,21.6032343 61.7071657,21.6032343 Z M62.2589249,20.2816102 C64.6587659,20.2816102 65.7386197,18.0395579 65.7386197,15.4435192 C65.7386197,12.847456 64.6587659,10.6054037 62.2589249,10.6054037 C59.8593329,10.6054037 58.7794792,12.847456 58.7794792,15.4435192 C58.7794792,18.0395579 59.8593329,20.2816102 62.2589249,20.2816102 Z"
                        id="Shape"></path>
                    <path
                        d="M71.4006902,21.272816 L71.2086331,21.0840266 L71.2086331,20.1872155 L71.4006902,19.9984015 L71.7606414,19.9984015 C72.2404103,19.9984015 72.6484381,19.5972056 72.6484381,19.1251829 L72.6484381,12.5878644 C72.6484381,12.0686321 72.2404103,11.7382384 71.7606414,11.7382384 L71.4006902,11.7382384 L71.2086331,11.5494244 L71.2086331,10.9594145 L71.3526136,10.770625 L74.4001178,9.6141978 L75.0719438,9.6141978 L75.2400871,9.77939469 L75.0241164,11.5022393 C75.9119131,10.1569981 77.3278043,9.28377951 79.2234645,9.28377951 C81.6950467,9.28377951 83.3747362,10.6526133 83.3747362,13.2486764 L83.3747362,19.1251829 C83.3747362,19.5972056 83.782764,19.9984015 84.262782,19.9984015 L84.6227333,19.9984015 L84.8145412,20.1872155 L84.8145412,21.0840266 L84.6227333,21.272816 L79.6073295,21.272816 L79.4155216,21.0840266 L79.4155216,20.1872155 L79.6073295,19.9984015 L79.9672808,19.9984015 C80.4472988,19.9984015 80.8550775,19.5972056 80.8550775,19.1251829 L80.8550775,13.4138733 C80.8550775,11.8562501 80.0153573,10.8178102 78.4076581,10.8178102 C76.3919311,10.8178102 75.1680969,12.5406548 75.1680969,14.4522888 L75.1680969,19.1251829 C75.1680969,19.5972056 75.5758755,19.9984015 76.0558936,19.9984015 L76.4158448,19.9984015 L76.6079018,20.1872155 L76.6079018,21.0840266 L76.4158448,21.272816 L71.4006902,21.272816 Z"
                        id="Path"></path>
                    <path
                        d="M92.0090823,21.6032343 C89.0335684,21.6032343 86.3699291,19.5735885 86.3699291,15.4907043 C86.3699291,11.5494244 89.2253763,9.28377951 92.2489668,9.28377951 C94.4086743,9.28377951 95.6564222,10.345812 96.352411,11.2426232 L96.4724778,11.2426232 L97.5523315,9.6141978 L98.3920517,9.6141978 L98.5841087,9.80298725 L98.5841087,18.4643709 C98.5841087,19.0307882 99.0399639,19.4083916 99.519982,19.4083916 L99.807943,19.4083916 L100,19.5971811 L100,20.2344006 L99.807943,20.4232145 L97.0723135,21.5088396 L96.3043345,21.5088396 L96.1125265,21.3200257 L96.1125265,19.4556012 C95.0565865,20.9896319 93.664858,21.6032343 92.0090823,21.6032343 Z M92.4891003,20.2816102 C94.8645295,20.2816102 95.968546,18.0159653 95.968546,15.4435192 C95.968546,12.847456 94.8645295,10.6054037 92.4891003,10.6054037 C90.0653456,10.6054037 88.9854918,12.847456 88.9854918,15.4435192 C88.9854918,18.0159653 90.0653456,20.2816102 92.4891003,20.2816102 Z"
                        id="Shape"></path>
                    <polygon id="Path"
                             points="14.419785 0 28.8397558 0 28.8397558 14.1819666 14.419785 14.1819666"></polygon>
                    <polygon id="Path"
                             points="8.84708973e-16 14.1819666 14.419785 14.1819666 14.4199344 21.272816 8.84708973e-16 21.272816"></polygon>
                    <polygon id="Path"
                             points="14.4199344 21.272816 28.8397558 21.272816 28.8397558 26 14.419785 26"></polygon>
                </g>
            </g>
        </svg>
    )
};