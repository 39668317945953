import EdnaWithTextLogo from "./EdnaWithTextLogo";
export const TermsAndConditions = () => {
  return (
	<div className="container">
		<div className="header">
		  <div className="logo-and-links">
			<EdnaWithTextLogo color="#ffffff" />
		  </div>
		</div>
  
		<div className="license">
		  <main>
			<h1>Edna Invest End User License Agreement</h1>
			<p>
				This Edna Invest End User License Agreement ("<b>Agreement</b>") is a binding agreement between
				you ("<b>End User</b>" or "<b>you</b>") and Edna Technologies, LLC ("<b>Edna</b>"). This Agreement governs
				your use of Edna Invest platform (“<b>Edna Invest</b>”) whether accessed through mobile devices
				and/or the web.
			</p>
			<p>
				BY CLICKING THE "ACCEPT", YOU: (A) ACKNOWLEDGE THAT YOU HAVE READ
				AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE 18 YEARS
				OF AGE OR OLDER OR OF LEGAL AGE TO ENTER INTO A BINDING AGREEMENT;
				AND (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND
				BY ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE EDNA
				INVEST.
			</p>
			<p>
				<b>Edna Invest provides a platform intended to facilitate certain types of investment
				transactions. Edna does not endorse, recommend, or promote any specific investments,
				financial products, or services. The presence of any information, tools, or functionalities
				within the Edna Invest platform does not constitute an endorsement or recommendation of
				any specific investment strategy or financial product. You should not interpret any content
				provided through Edna Invest as legal, tax, investment, financial, or other advice. You are
				solely responsible for your use of Edna Invest.</b>
			</p>
			<ol>
			  <li>
					<p><u>License Grant.</u> Subject to the terms of this Agreement, the eligibility requirements
					set forth in Section 2, Edna grants you a limited, non-exclusive, and nontransferable license to
					download, install, access and use Edna Invest for your personal, non-commercial use, accessible
					on devices (including, but not limited to, mobile devices and computers) owned or otherwise
					controlled by you ("<b>Device</b>").</p>				
			  </li>
			  <li>
					<p><u>License Eligibility.</u> Edna has partnered with your employer (the “<b>Employer</b>” or
					“<b>your Employer</b>”) to make Edna Invest available to you. Your Employer is not a party to this
					Agreement. For you to create an account on Edna Invest (each a “<b>User Account</b>”), your
					Employer must have entered into a written contract with us (an “<b>Employer Agreement</b>”). You
					understand that your ability to use Edna Invest depends on (1) your Employer’s determination of
					who is eligible to receive Edna Invest; (2) the Employer Agreement remaining effective, and (3)
					the Employer’s compliance with the Employer Agreement. If at any time your Employer changes
					your eligibility status, violates certain terms of the Employer Agreement, or the Employer
					Agreement expires or is terminated, your access to Edna Invest may be suspended or terminated
					as a result.</p>				
			  </li>
			  <li>
					<p><u>User Account.</u> Each End User must have their own User Account to access Edna
					Invest. When creating your User Account, you must provide accurate and complete information,
					and you must keep this information up-to-date. Edna relies on this information to provide the
					service and does not independently verify the information you give us. If you give us incorrect
					information, your ability to access and use Edna Invest, including processing of investments or
					transactions, may be impacted. Edna has no liability for any incorrect information that you
					provide. You are solely responsible for the activity that occurs on your User Account, whether or
					not authorized by you, and you must keep your User Account password secure. You must notify
					Edna immediately of any breach of security or unauthorized use of your User Account.</p>
			  </li>
			  <li>
				<p><u>License Restrictions.</u> Except as may be expressly permitted by applicable law or
				expressly authorized by Edna, you shall not:</p>
				<ol type="a">
					<li>
						copy Edna Invest, except as expressly permitted by this license;
					</li>
					<li>
						modify, translate, adapt, or otherwise create derivative works or
						improvements, whether or not patentable, of Edna Invest;
					</li>
					<li>
						reverse engineer, disassemble, decompile, decode, or otherwise attempt to
						derive or gain access to the source code of Edna Invest or any part thereof;
					</li>
					<li>
						remove, delete, alter, or obscure any trademarks or any copyright,
						trademark, patent, or other intellectual property or proprietary rights notices from Edna
						Invest, including any copy thereof;
					</li>
					<li>
						rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or
						otherwise make available Edna Invest, or any features or functionality of Edna Invest, to
						any third party for any reason, including by making Edna Invest available on a network
						where it is capable of being accessed by more than one device at any time;
					</li>
					<li>
						use any robot, spider, or other automatic device, process, or means to
						access Edna Invest for any purpose, including monitoring or copying any of the material
						on Edna Invest;
					</li>
					<li>
						use any manual process to monitor or copy any of the material on Edna
						Invest, or for any other purpose not expressly authorized in this Agreement, without
						Edna's prior written consent;
					</li>
					<li>
						frame, mirror, or otherwise incorporate Edna Invest or any portion of Edna
						Invest as part of any other mobile application, website, or service;
					</li>
					<li>
						use Edna Invest in any manner that could disable, overburden, damage, or
						impair Edna Invest or interfere with any other party's use of Edna Invest; or
					</li>
					<li>
						remove, disable, circumvent, or otherwise create or implement any
						workaround to any copy protection, rights management, or security features in or
						protecting Edna Invest.
					</li>
				</ol>
		  	  </li>
			  <li>
				<p><u>Reservation of Rights.</u> You acknowledge and agree that Edna Invest is provided
				under license, and not sold, to you. You do not acquire any ownership interest in Edna Invest
				under this Agreement, or any other rights thereto other than to use Edna Invest in accordance
				with the license granted, and subject to all terms, conditions, and restrictions, under this
				Agreement.</p>
			  </li>
			  <li>
				<p><u>Collection and Use of Your Information.</u> You acknowledge that when you use Edna Invest, Edna
				may use automatic means (including, for example, cookies and web beacons) to collect
				information about your Device and about your use of Edna Invest. All information we collect
				through or in connection with Edna Invest is subject to our Privacy Policy <a href="https://withedna.com/legal">https://withedna.com/legal</a>. 
				By downloading, installing, using, and providing information to or
				through Edna Invest, you consent to all actions taken by us with respect to your information in
				compliance with the Privacy Policy.</p>
			  </li>
			  <li>
				<p><u>Updates.</u> Edna may from time to time in its sole discretion develop and provide updates to
				mobile Devices, which may include upgrades, bug fixes, patches, other error corrections, and/or
				new features (collectively, including related documentation, "Updates"). Updates may also
				modify or delete in their entirety certain features and functionality. You agree that Edna has no
				obligation to provide any Updates or to continue to provide or enable any particular features or
				functionality. Based on your Device type, settings, when your Device is connected to the internet
				either:</p>
				<ol type="a">
					<li>
						Edna Invest will automatically download and install all available Updates; or
					</li>
					<li>
						you may receive notice of or be prompted to download and install available Updates.
					</li>
				</ol>
				<p>You shall promptly download and install all Updates and acknowledge and agree
				that Edna Invest or portions thereof may not properly operate should you fail to do so. You
				further agree that all Updates will be deemed part of Edna Invest and be subject to all terms and
				conditions of this Agreement.</p>
			  </li>		
			  <li>
				<p><u>Third-Party Materials.</u> Edna Invest may display, include, or make available third-party content
				(including data, information, applications, and other products, services, and/or materials) or
				provide links to third-party websites or services, including through third-party advertising
				("<b>Third-Party Materials</b>"). You acknowledge and agree that Edna is not responsible for ThirdParty Materials, including their accuracy, completeness, timeliness, validity, copyright
				compliance, legality, decency, quality, or any other aspect thereof. Edna does not assume and
				will not have any liability or responsibility to you or any other person or entity for any ThirdParty Materials. Third-Party Materials and links thereto are provided solely as a convenience to
				you, and you access and use them entirely at your own risk and subject to such third parties'
				terms and conditions.</p>	
			  </li>
			  <li>
				<p><u>Term and Termination.</u></p>
				
				<p>The term of Agreement commences when you create a User Account and will continue in effect
				until terminated by you or Edna as set forth in this Section 0.</p>

				<p>You may terminate this Agreement by requesting that Edna terminate your User Account.</p>

				<p>Edna may terminate your User Account at any time without notice if the relationship between
				your Employer and Edna terminates, or if your Employer designates you as no longer eligible to
				access Edna Invest.</p>

				<p>Edna may terminate or suspend your User Account immediately if you violate any of the terms
				and conditions of this Agreement.</p>

				<p>Depending on the manner of your termination, and upon Edna’s discretion, Edna may continue
				to provide you with read only access to your User Account.</p>

				<p>Termination will not limit any of Edna's rights or remedies at law or in equity</p>
			  </li>
			  <li>
				<p><u>Disclaimer of Warranties.</u> EDNA INVEST IS PROVIDED TO END USER "AS IS" AND
				WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE
				MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, EDNA, ON ITS OWN
				BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE
				LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL
				WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH
				RESPECT TO THE APPLICATION, INCLUDING ALL IMPLIED WARRANTIES OF
				MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF
				DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT
				LIMITATION TO THE FOREGOING, COMPANY PROVIDES NO WARRANTY OR
				UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE
				APPLICATION WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED
				RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE,
				APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION,
				MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE ERROR-FREE, OR
				THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.</p>

				<p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR
				LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE
				APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE
				ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.</p>
			  </li>
			  <li>
				<p><u>Limitation of Liability.</u> TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW,
				IN NO EVENT WILL EDNA OR ITS AFFILIATES, OR ANY OF ITS OR THEIR
				RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING
				FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE APPLICATION OR
				THE CONTENT AND SERVICES FOR:</p>
				
				<p>LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS
				OF GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR
				MALFUNCTION, OR ANY OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT,
				EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES.</p>			

				<p>DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE
				AMOUNT ACTUALLY PAID BY YOU FOR THE APPLICATION.</p>

				<p>THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH
				DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING
				NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES
				WERE FORESEEABLE OR EDNA WAS ADVISED OF THE POSSIBILITY OF SUCH
				DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF
				LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY
				NOT APPLY TO YOU.</p>
			  </li>
			  <li>
				<p><u>Export Regulation.</u> Edna Invest may be subject to US export control laws, including the Export
				Control Reform Act and its associated regulations. You shall not, directly or indirectly, export,
				re-export, or release Edna Invest to, or make Edna Invest accessible from, any jurisdiction or
				country to which export, re-export, or release is prohibited by law, rule, or regulation. You shall
				comply with all applicable federal laws, regulations, and rules, and complete all required
				undertakings (including obtaining any necessary export license or other governmental approval),
				prior to exporting, re-exporting, releasing, or otherwise making Edna Invest available outside the
				US.</p>
			  </li>
			  <li>
				<p><u>Severability.</u> If any provision of this Agreement is illegal or unenforceable under applicable law,
				the remainder of the provision will be amended to achieve as closely as possible the effect of the
				original term and all other provisions of this Agreement will continue in full force and effect.</p>
			  </li>
			  <li>
				<p><u>Governing Law; Arbitration.</u> New York law governs this Agreement, without application of
				principles of conflicts of law that would result in the application of any other state’s laws. Any
				claim, controversy or dispute arising out of or related to this Agreement shall be settled
				exclusively by arbitration, except for injunctive or other equitable relief necessary to maintain
				the status quo pending arbitration, which shall be conducted before a panel of three arbitrators in
				New York, New York in accordance with the rules of the American Arbitration Association then
				in effect. Judgment on the award rendered by the arbitrators may be entered and enforced by the
				state or federal courts located in New York County, New York (USA).</p>	
			  </li>
			  <li>
				<p><u>Entire Agreement.</u> This Agreement and our Privacy Policy constitute the entire agreement
				between you and Edna with respect to Edna Invest and supersede all prior or contemporaneous
				understandings and agreements, whether written or oral, with respect to Edna Invest.</p>
			  </li>
			  <li>
				<p><u>Waiver.</u> No failure to exercise, and no delay in exercising, on the part of either party, any right or
				any power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of
				any right or power hereunder preclude further exercise of that or any other right hereunder. In the
				event of a conflict between this Agreement and any applicable purchase or other terms, the terms
				of this Agreement shall govern.</p>
			  </li>
			</ol>
		  </main>
		</div>
	  </div>
  );
};
