import "./App.css";
import { Home } from "./Home";
import { Legal } from "./Legal";
import { TermsAndConditions } from "./TermsAndConditions";

function App() {
  const { pathname } = window.location;

  if (pathname === "/legal") {
    return <Legal />;
  }

  if (pathname === "/terms-and-conditions") {
    return <TermsAndConditions />;
  }

  return <Home />;
}

export default App;
